import { useMemo } from "react";
import { lineHeights } from "ui/src/components/buttons/Button/constants";
import { getLineHeightForButtonFontTokenKey } from "ui/src/components/buttons/Button/utils/getLineHeightForButtonFontTokenKey";
const getIconSizes = () => ({
  xxsmall: getLineHeightForButtonFontTokenKey(lineHeights.xxsmall),
  xsmall: getLineHeightForButtonFontTokenKey(lineHeights.xsmall),
  small: getLineHeightForButtonFontTokenKey(lineHeights.small),
  medium: getLineHeightForButtonFontTokenKey(lineHeights.medium),
  large: getLineHeightForButtonFontTokenKey(lineHeights.large)
});
export const useIconSizes = () => useMemo(() => getIconSizes(), []);
