import { useStyle } from "tamagui";
import { CustomButtonText } from "ui/src/components/buttons/Button/components/CustomButtonText";
import { useIconSizes } from "ui/src/components/buttons/Button/hooks/useIconSizes";
import { SpinningLoader } from "ui/src/loading/SpinningLoader";
export const ThemedSpinningLoader = ({
  size = "medium",
  variant,
  emphasis,
  disabled
}) => {
  const iconSizes = useIconSizes();
  const { color } = useStyle({ variant, emphasis, disabled }, { forComponent: CustomButtonText });
  const loaderSize = iconSizes[size];
  return <SpinningLoader color={color} size={loaderSize} />;
};
