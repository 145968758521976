"use strict";
import { InterfaceElementName, InterfaceEventName } from "@uniswap/analytics-events";
import { useAccountDrawer } from "components/AccountDrawer/MiniPortfolio/hooks";
import { ConnectWalletButtonText } from "components/NavBar/accountCTAsExperimentUtils";
import Column from "components/deprecated/Column";
import { useAccount } from "hooks/useAccount";
import { useGroupedRecentTransfers } from "hooks/useGroupedRecentTransfers";
import { useSendCallback } from "hooks/useSendCallback";
import { NewAddressSpeedBumpModal } from "pages/Swap/Send/NewAddressSpeedBump";
import SendCurrencyInputForm from "pages/Swap/Send/SendCurrencyInputForm";
import { SendRecipientForm } from "pages/Swap/Send/SendRecipientForm";
import { SendReviewModal } from "pages/Swap/Send/SendReviewModal";
import { SmartContractSpeedBumpModal } from "pages/Swap/Send/SmartContractSpeedBump";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { SendContextProvider, useSendContext } from "state/send/SendContext";
import { DeprecatedButton, Text } from "ui/src";
import Trace from "uniswap/src/features/telemetry/Trace";
import { InterfacePageNameLocal } from "uniswap/src/features/telemetry/constants";
import { useIsSmartContractAddress } from "utils/transfer";
function useSendButtonState() {
  const { sendState, derivedSendInfo } = useSendContext();
  const { recipient } = sendState;
  const { parsedTokenAmount, recipientData } = derivedSendInfo;
  return useMemo(() => {
    if (recipient && !recipientData) {
      return {
        label: <Trans i18nKey="common.invalidRecipient.error" />,
        disabled: true
      };
    }
    if (!parsedTokenAmount) {
      return {
        label: <Trans i18nKey="common.noAmount.error" />,
        disabled: true
      };
    }
    if (!recipient && !recipientData) {
      return {
        label: <Trans i18nKey="common.input.noRecipient.error" />,
        disabled: true
      };
    }
    return {
      label: <Trans i18nKey="common.send.button" />,
      disabled: false
    };
  }, [parsedTokenAmount, recipient, recipientData]);
}
var SendFormModalState = /* @__PURE__ */ ((SendFormModalState2) => {
  SendFormModalState2["None"] = "None";
  SendFormModalState2["SMART_CONTRACT_SPEED_BUMP"] = "SMART_CONTRACT_SPEED_BUMP";
  SendFormModalState2["NEW_ADDRESS_SPEED_BUMP"] = "NEW_ADDRESS_SPEED_BUMP";
  SendFormModalState2["REVIEW"] = "REVIEW";
  return SendFormModalState2;
})(SendFormModalState || {});
var SendSpeedBump = /* @__PURE__ */ ((SendSpeedBump2) => {
  SendSpeedBump2["SMART_CONTRACT_SPEED_BUMP"] = "SMART_CONTRACT_SPEED_BUMP";
  SendSpeedBump2["NEW_ADDRESS_SPEED_BUMP"] = "NEW_ADDRESS_SPEED_BUMP";
  return SendSpeedBump2;
})(SendSpeedBump || {});
function SendFormInner({ disableTokenInputs = false, onCurrencyChange }) {
  const account = useAccount();
  const accountDrawer = useAccountDrawer();
  const [sendFormModalState, setSendFormModalState] = useState("None" /* None */);
  const [sendFormSpeedBumpState, setSendFormSpeedBumpState] = useState({
    ["NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */]: false,
    ["SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */]: false
  });
  const { setSendState, derivedSendInfo } = useSendContext();
  const { inputError, parsedTokenAmount, recipientData, transaction, gasFee } = derivedSendInfo;
  const { isSmartContractAddress, loading: loadingSmartContractAddress } = useIsSmartContractAddress(
    recipientData?.address
  );
  const { transfers: recentTransfers, loading: transfersLoading } = useGroupedRecentTransfers(account.address);
  const isRecentAddress = useMemo(() => {
    if (!recipientData?.address) {
      return void 0;
    }
    return !!recentTransfers?.[recipientData.address];
  }, [recentTransfers, recipientData?.address]);
  const sendButtonState = useSendButtonState();
  const sendCallback = useSendCallback({
    currencyAmount: parsedTokenAmount,
    recipient: recipientData?.address,
    transactionRequest: transaction,
    gasFee
  });
  const handleModalState = useCallback((newState) => {
    setSendFormModalState(newState ?? "None" /* None */);
  }, []);
  useEffect(() => {
    setSendFormSpeedBumpState(() => ({
      ["SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */]: isSmartContractAddress,
      ["NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */]: !isRecentAddress
    }));
  }, [isRecentAddress, isSmartContractAddress, recipientData?.address]);
  const handleSendButton = useCallback(
    (prevSpeedBump) => {
      if (prevSpeedBump !== "SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */ && sendFormSpeedBumpState["SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */]) {
        handleModalState("SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */);
        return;
      }
      if (prevSpeedBump !== "NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */ && sendFormSpeedBumpState["NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */]) {
        handleModalState("NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */);
        return;
      }
      handleModalState("REVIEW" /* REVIEW */);
    },
    [handleModalState, sendFormSpeedBumpState]
  );
  const handleConfirmSmartContractSpeedBump = useCallback(() => {
    setSendFormSpeedBumpState((prev) => ({
      ...prev,
      ["SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */]: false
    }));
    handleModalState("None" /* None */);
    handleSendButton("SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */);
  }, [handleModalState, handleSendButton]);
  const handleCancelSmartContractSpeedBump = useCallback(
    () => handleModalState("None" /* None */),
    [handleModalState]
  );
  const handleConfirmNewAddressSpeedBump = useCallback(() => {
    setSendFormSpeedBumpState((prev) => ({
      ...prev,
      ["NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */]: false
    }));
    handleModalState("None" /* None */);
    handleSendButton("NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */);
  }, [handleModalState, handleSendButton]);
  const handleCancelNewAddressSpeedBump = useCallback(
    () => handleModalState("None" /* None */),
    [handleModalState]
  );
  const handleSend = useCallback(() => {
    sendCallback().then(() => {
      handleModalState("None" /* None */);
      setSendState((prev) => ({
        ...prev,
        exactAmountToken: void 0,
        exactAmountFiat: "",
        recipient: "",
        validatedRecipient: void 0,
        inputInFiat: true
      }));
    }).catch(() => void 0);
  }, [handleModalState, sendCallback, setSendState]);
  const buttonDisabled = !!inputError || loadingSmartContractAddress || transfersLoading || sendButtonState.disabled;
  return <><Column gap="xs"><SendCurrencyInputForm disabled={disableTokenInputs} onCurrencyChange={onCurrencyChange} /><SendRecipientForm disabled={disableTokenInputs} />{account.isDisconnected ? <Trace
    logPress
    eventOnTrigger={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
    element={InterfaceElementName.CONNECT_WALLET_BUTTON}
  ><DeprecatedButton
    animation="fast"
    size="large"
    borderRadius="$rounded16"
    width="100%"
    pressStyle={{ scale: 0.98 }}
    opacity={1}
    onPress={accountDrawer.open}
    backgroundColor="$accent2"
    hoverStyle={{
      backgroundColor: "$accent2Hovered"
    }}
  ><Text variant="buttonLabel1" color="$accent1"><ConnectWalletButtonText /></Text></DeprecatedButton></Trace> : <Trace logPress element={InterfaceElementName.SEND_BUTTON}><DeprecatedButton
    animation="fast"
    size="large"
    borderRadius="$rounded16"
    width="100%"
    pressStyle={{ scale: 0.98 }}
    disabled={buttonDisabled}
    opacity={1}
    onPress={() => handleSendButton()}
    backgroundColor={buttonDisabled ? "$surface2" : "$accent1"}
  ><Text variant="buttonLabel1" color={buttonDisabled ? "$neutral2" : "$white"}>{sendButtonState.label}</Text></DeprecatedButton></Trace>}</Column>{sendFormModalState === "REVIEW" /* REVIEW */ ? <SendReviewModal onConfirm={handleSend} onDismiss={() => handleModalState("None" /* None */)} /> : sendFormModalState === "SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */ ? <SmartContractSpeedBumpModal
    onCancel={handleCancelSmartContractSpeedBump}
    onConfirm={handleConfirmSmartContractSpeedBump}
  /> : sendFormModalState === "NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */ ? <NewAddressSpeedBumpModal
    onCancel={handleCancelNewAddressSpeedBump}
    onConfirm={handleConfirmNewAddressSpeedBump}
  /> : null}</>;
}
export function SendForm(props) {
  return <Trace page={InterfacePageNameLocal.Send}><SendContextProvider><SendFormInner {...props} /></SendContextProvider></Trace>;
}
