"use strict";
import { ScrollBarStyles } from "components/Common/styles";
import { CurrencyRow } from "components/SearchModal/CurrencyList";
import { HeaderContent } from "pages/Swap/Buy/CountryListModal";
import { ContentWrapper } from "pages/Swap/Buy/shared";
import { useTranslation } from "react-i18next";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { CloseIcon } from "theme/components";
import { Flex, useMedia } from "ui/src";
import { Text } from "ui/src/components/text/Text";
import { Modal } from "uniswap/src/components/modals/Modal";
import { ModalName } from "uniswap/src/features/telemetry/constants";
const ROW_ITEM_SIZE = 56;
export function FiatOnRampCurrencyModal({
  isOpen,
  onDismiss,
  currencies,
  selectedCurrency,
  onSelectCurrency
}) {
  const { t } = useTranslation();
  const sm = useMedia().sm;
  return <Modal
    name={ModalName.FiatOnRampTokenSelector}
    maxWidth={420}
    height={sm ? "100vh" : "100%"}
    maxHeight={700}
    isModalOpen={isOpen}
    onClose={onDismiss}
    padding={0}
  ><ContentWrapper><HeaderContent><Flex row justifyContent="space-between"><Text variant="subheading1">{t("common.selectToken.label")}</Text><CloseIcon data-testid="FiatOnRampCurrencyModal-close" onClick={onDismiss} /></Flex></HeaderContent><Flex grow><AutoSizer disableWidth>{({ height }) => <div data-testid="for-currency-list-wrapper"><FixedSizeList
    height={height}
    width="100%"
    itemData={currencies}
    itemCount={currencies.length}
    itemSize={ROW_ITEM_SIZE}
    itemKey={(index, data) => data[index]?.meldCurrencyCode ?? index}
    {...ScrollBarStyles}
  >{({ style, data, index }) => {
    const currencyInfo = data[index].currencyInfo;
    if (!currencyInfo) {
      return null;
    }
    return <CurrencyRow
      style={style}
      currencyInfo={currencyInfo}
      onSelect={() => {
        onSelectCurrency(data[index]);
        onDismiss();
      }}
      isSelected={selectedCurrency?.meldCurrencyCode === data[index].meldCurrencyCode}
      eventProperties={{}}
      otherSelected={false}
    />;
  }}</FixedSizeList></div>}</AutoSizer></Flex></ContentWrapper></Modal>;
}
