import { cloneElement } from "react";
import { useStyle } from "tamagui";
import { CustomButtonText } from "ui/src/components/buttons/Button/components/CustomButtonText";
import { useIconSizes } from "ui/src/components/buttons/Button/hooks/useIconSizes";
export const ThemedIcon = ({
  children,
  size = "medium",
  variant,
  disabled,
  emphasis
}) => {
  const iconSizes = useIconSizes();
  const { color } = useStyle(
    { variant, emphasis, disabled },
    {
      forComponent: CustomButtonText
    }
  );
  if (!children) {
    return null;
  }
  const width = iconSizes[size];
  const height = width;
  return cloneElement(children, {
    color,
    width,
    height
  });
};
