"use strict";
import { useAtom } from "jotai";
import { getExploreDescription, getExploreTitle } from "pages/getExploreTitle";
import { getAddLiquidityPageTitle, getPositionPageDescription, getPositionPageTitle } from "pages/getPositionPageTitle";
import { Suspense, lazy, useMemo } from "react";
import { Navigate, Route, Routes, matchPath, useLocation } from "react-router-dom";
import { shouldDisableNFTRoutesAtom } from "state/application/atoms";
import { isBrowserRouterEnabled } from "utils/env";
import Landing from "pages/Landing";
import Swap from "pages/Swap";
import i18n from "uniswap/src/i18n";
const NftExplore = lazy(() => import("nft/pages/explore"));
const Collection = lazy(() => import("nft/pages/collection"));
const CreatePosition = lazy(() => import("pages/Pool/Positions/create/CreatePosition"));
const Profile = lazy(() => import("nft/pages/profile"));
const Asset = lazy(() => import("nft/pages/asset/Asset"));
const AddLiquidityV3WithTokenRedirects = lazy(() => import("pages/AddLiquidityV3/redirects"));
const AddLiquidityV2WithTokenRedirects = lazy(() => import("pages/AddLiquidityV2/redirects"));
const RedirectExplore = lazy(() => import("pages/Explore/redirects"));
const MigrateV2 = lazy(() => import("pages/MigrateV2"));
const MigrateV2Pair = lazy(() => import("pages/MigrateV2/MigrateV2Pair"));
const MigrateV3 = lazy(() => import("pages/MigrateV3"));
const NotFound = lazy(() => import("pages/NotFound"));
const Pool = lazy(() => import("pages/Pool"));
const LegacyPoolRedirects = lazy(
  () => import("pages/LegacyPool/redirects").then((module) => ({ default: module.LegacyPoolRedirects }))
);
const PoolFinderRedirects = lazy(
  () => import("pages/LegacyPool/redirects").then((module) => ({ default: module.PoolFinderRedirects }))
);
const LegacyPoolV2Redirects = lazy(
  () => import("pages/LegacyPool/redirects").then((module) => ({ default: module.LegacyPoolV2Redirects }))
);
const LegacyPositionPageRedirects = lazy(
  () => import("pages/LegacyPool/redirects").then((module) => ({ default: module.LegacyPositionPageRedirects }))
);
const PositionPage = lazy(() => import("pages/Pool/Positions/PositionPage"));
const V2PositionPage = lazy(() => import("pages/Pool/Positions/V2PositionPage"));
const PoolDetails = lazy(() => import("pages/PoolDetails"));
const RemoveLiquidityV2 = lazy(() => import("pages/RemoveLiquidity/V2"));
const RemoveLiquidityV3 = lazy(() => import("pages/RemoveLiquidity/V3"));
const TokenDetails = lazy(() => import("pages/TokenDetails"));
export function useRouterConfig() {
  const browserRouterEnabled = isBrowserRouterEnabled();
  const { hash } = useLocation();
  const [shouldDisableNFTRoutes] = useAtom(shouldDisableNFTRoutesAtom);
  return useMemo(
    () => ({
      browserRouterEnabled,
      hash,
      shouldDisableNFTRoutes: Boolean(shouldDisableNFTRoutes)
    }),
    [browserRouterEnabled, hash, shouldDisableNFTRoutes]
  );
}
const StaticTitlesAndDescriptions = {
  UniswapTitle: i18n.t("title.uniswapTradeCrypto"),
  SwapTitle: i18n.t("title.buySellTradeEthereum"),
  SwapDescription: i18n.t("title.swappingMadeSimple"),
  DetailsPageBaseTitle: i18n.t("common.buyAndSell"),
  TDPDescription: i18n.t("title.realTime"),
  PDPDescription: i18n.t("title.tradeTokens"),
  NFTTitle: i18n.t("title.explore"),
  MigrateTitle: i18n.t("title.migratev2"),
  MigrateTitleV3: i18n.t("title.migratev3"),
  MigrateDescription: i18n.t("title.easilyRemove"),
  MigrateDescriptionV4: i18n.t("title.easilyRemoveV4"),
  AddLiquidityDescription: i18n.t("title.earnFees")
};
function createRouteDefinition(route) {
  return {
    getElement: () => null,
    getTitle: () => StaticTitlesAndDescriptions.UniswapTitle,
    getDescription: () => StaticTitlesAndDescriptions.SwapDescription,
    enabled: () => true,
    path: "/",
    nestedPaths: [],
    // overwrite the defaults
    ...route
  };
}
export const routes = [
  createRouteDefinition({
    path: "/",
    getTitle: () => StaticTitlesAndDescriptions.UniswapTitle,
    getDescription: () => StaticTitlesAndDescriptions.SwapDescription,
    getElement: (args) => {
      return args.browserRouterEnabled && args.hash ? <Navigate to={args.hash.replace("#", "")} replace /> : <Landing />;
    }
  }),
  createRouteDefinition({
    path: "/explore",
    getTitle: getExploreTitle,
    getDescription: getExploreDescription,
    nestedPaths: [":tab", ":chainName", ":tab/:chainName"],
    getElement: () => <RedirectExplore />
  }),
  createRouteDefinition({
    path: "/explore/tokens/:chainName/:tokenAddress",
    getTitle: () => i18n.t("common.buyAndSell"),
    getDescription: () => StaticTitlesAndDescriptions.TDPDescription,
    getElement: () => <TokenDetails />
  }),
  createRouteDefinition({
    path: "/tokens",
    getTitle: getExploreTitle,
    getDescription: getExploreDescription,
    getElement: () => <Navigate to="/explore/tokens" replace />
  }),
  createRouteDefinition({
    path: "/tokens/:chainName",
    getTitle: getExploreTitle,
    getDescription: getExploreDescription,
    getElement: () => <RedirectExplore />
  }),
  createRouteDefinition({
    path: "/tokens/:chainName/:tokenAddress",
    getTitle: () => StaticTitlesAndDescriptions.DetailsPageBaseTitle,
    getDescription: () => StaticTitlesAndDescriptions.TDPDescription,
    getElement: () => <RedirectExplore />
  }),
  createRouteDefinition({
    path: "/explore/pools/:chainName/:poolAddress",
    getTitle: () => StaticTitlesAndDescriptions.DetailsPageBaseTitle,
    getDescription: () => StaticTitlesAndDescriptions.PDPDescription,
    getElement: () => <Suspense fallback={null}><PoolDetails /></Suspense>
  }),
  createRouteDefinition({
    path: "/vote/*",
    getTitle: () => i18n.t("title.voteOnGov"),
    getDescription: () => i18n.t("title.uniToken"),
    getElement: () => {
      return <Routes><Route
        path="*"
        Component={() => {
          window.location.href = "https://vote.uniswapfoundation.org";
          return null;
        }}
      /></Routes>;
    }
  }),
  createRouteDefinition({
    path: "/create-proposal",
    getTitle: () => i18n.t("title.createGovernanceOn"),
    getDescription: () => i18n.t("title.createGovernanceTo"),
    getElement: () => <Navigate to="/vote/create-proposal" replace />
  }),
  createRouteDefinition({
    path: "/buy",
    getElement: () => <Swap />,
    getTitle: () => StaticTitlesAndDescriptions.SwapTitle
  }),
  createRouteDefinition({
    path: "/send",
    getElement: () => <Swap />,
    getTitle: () => i18n.t("title.sendTokens")
  }),
  createRouteDefinition({
    path: "/limits",
    getElement: () => <Navigate to="/limit" replace />,
    getTitle: () => i18n.t("title.placeLimit")
  }),
  createRouteDefinition({
    path: "/limit",
    getElement: () => <Swap />,
    getTitle: () => i18n.t("title.placeLimit")
  }),
  createRouteDefinition({
    path: "/buy",
    getElement: () => <Swap />,
    getTitle: () => StaticTitlesAndDescriptions.SwapTitle
  }),
  createRouteDefinition({
    path: "/swap",
    getElement: () => <Swap />,
    getTitle: () => StaticTitlesAndDescriptions.SwapTitle
  }),
  // Refreshed pool routes
  createRouteDefinition({
    path: "/positions/create",
    getElement: () => <CreatePosition />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription,
    nestedPaths: [":protocolVersion"]
  }),
  createRouteDefinition({
    path: "/positions",
    getElement: () => <Pool />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription
  }),
  createRouteDefinition({
    path: "/positions/v2/:chainName/:pairAddress",
    getElement: () => <V2PositionPage />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription
  }),
  createRouteDefinition({
    path: "/positions/v3/:chainName/:tokenId",
    getElement: () => <PositionPage />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription
  }),
  createRouteDefinition({
    path: "/positions/v4/:chainName/:tokenId",
    getElement: () => <PositionPage />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription
  }),
  createRouteDefinition({
    path: "/migrate/v3/:chainName/:tokenId",
    getElement: () => <MigrateV3 />,
    getTitle: () => StaticTitlesAndDescriptions.MigrateTitleV3,
    getDescription: () => StaticTitlesAndDescriptions.MigrateDescriptionV4
  }),
  // Legacy pool routes
  createRouteDefinition({
    path: "/pool",
    getElement: () => <LegacyPoolRedirects />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription
  }),
  createRouteDefinition({
    path: "/pool/v2/find",
    getElement: () => <PoolFinderRedirects />,
    getTitle: getPositionPageDescription,
    getDescription: getPositionPageDescription
  }),
  createRouteDefinition({
    path: "/pool/v2",
    getElement: () => <LegacyPoolV2Redirects />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription
  }),
  createRouteDefinition({
    path: "/pool/:tokenId",
    getElement: () => <LegacyPositionPageRedirects />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription
  }),
  createRouteDefinition({
    path: "/pools/v2/find",
    getElement: () => <PoolFinderRedirects />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription
  }),
  createRouteDefinition({
    path: "/pools/v2",
    getElement: () => <LegacyPoolV2Redirects />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription
  }),
  createRouteDefinition({
    path: "/pools",
    getElement: () => <LegacyPoolRedirects />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription
  }),
  createRouteDefinition({
    path: "/pools/:tokenId",
    getElement: () => <LegacyPositionPageRedirects />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription
  }),
  createRouteDefinition({
    path: "/add/v2",
    nestedPaths: [":currencyIdA", ":currencyIdA/:currencyIdB"],
    getElement: () => <AddLiquidityV2WithTokenRedirects />,
    getTitle: getAddLiquidityPageTitle,
    getDescription: () => StaticTitlesAndDescriptions.AddLiquidityDescription
  }),
  createRouteDefinition({
    path: "/add",
    nestedPaths: [
      ":currencyIdA",
      ":currencyIdA/:currencyIdB",
      ":currencyIdA/:currencyIdB/:feeAmount",
      ":currencyIdA/:currencyIdB/:feeAmount/:tokenId"
    ],
    getElement: () => <AddLiquidityV3WithTokenRedirects />,
    getTitle: getAddLiquidityPageTitle,
    getDescription: () => StaticTitlesAndDescriptions.AddLiquidityDescription
  }),
  createRouteDefinition({
    path: "/remove/v2/:currencyIdA/:currencyIdB",
    getElement: () => <RemoveLiquidityV2 />,
    getTitle: () => i18n.t("title.removeLiquidityv2"),
    getDescription: () => i18n.t("title.removeTokensv2")
  }),
  createRouteDefinition({
    path: "/remove/:tokenId",
    getElement: () => <RemoveLiquidityV3 />,
    getTitle: () => i18n.t("title.removePoolLiquidity"),
    getDescription: () => i18n.t("title.removev3Liquidity")
  }),
  createRouteDefinition({
    path: "/migrate/v2",
    getElement: () => <MigrateV2 />,
    getTitle: () => StaticTitlesAndDescriptions.MigrateTitle,
    getDescription: () => StaticTitlesAndDescriptions.MigrateDescription
  }),
  createRouteDefinition({
    path: "/migrate/v2/:address",
    getElement: () => <MigrateV2Pair />,
    getTitle: () => StaticTitlesAndDescriptions.MigrateTitle,
    getDescription: () => StaticTitlesAndDescriptions.MigrateDescription
  }),
  createRouteDefinition({
    path: "/nfts",
    getElement: () => <Suspense fallback={null}><NftExplore /></Suspense>,
    enabled: (args) => !args.shouldDisableNFTRoutes,
    getTitle: () => i18n.t("title.exploreNFTs"),
    getDescription: () => i18n.t("title.betterPricesMoreListings")
  }),
  createRouteDefinition({
    path: "/nfts/asset/:contractAddress/:tokenId",
    getElement: () => <Suspense fallback={null}><Asset /></Suspense>,
    enabled: (args) => !args.shouldDisableNFTRoutes,
    getTitle: () => StaticTitlesAndDescriptions.NFTTitle
  }),
  createRouteDefinition({
    path: "/nfts/profile",
    getElement: () => <Suspense fallback={null}><Profile /></Suspense>,
    enabled: (args) => !args.shouldDisableNFTRoutes,
    getTitle: () => StaticTitlesAndDescriptions.NFTTitle,
    getDescription: () => i18n.t("title.manageNFT")
  }),
  createRouteDefinition({
    path: "/nfts/collection/:contractAddress",
    getElement: () => <Suspense fallback={null}><Collection /></Suspense>,
    enabled: (args) => !args.shouldDisableNFTRoutes,
    getTitle: () => StaticTitlesAndDescriptions.NFTTitle
  }),
  createRouteDefinition({
    path: "/nfts/collection/:contractAddress/activity",
    getElement: () => <Suspense fallback={null}><Collection /></Suspense>,
    enabled: (args) => !args.shouldDisableNFTRoutes,
    getTitle: () => StaticTitlesAndDescriptions.NFTTitle
  }),
  createRouteDefinition({ path: "*", getElement: () => <Navigate to="/not-found" replace /> }),
  createRouteDefinition({ path: "/not-found", getElement: () => <NotFound /> })
];
export const findRouteByPath = (pathname) => {
  for (const route of routes) {
    const match = matchPath(route.path, pathname);
    if (match) {
      return route;
    }
    const subPaths = route.nestedPaths.map((nestedPath) => `${route.path}/${nestedPath}`);
    for (const subPath of subPaths) {
      const match2 = matchPath(subPath, pathname);
      if (match2) {
        return route;
      }
    }
  }
  return void 0;
};
