import { cloneElement, useState } from "react";
import { AnimatePresence, Tabs, styled } from "tamagui";
import { Flex } from "ui/src/components/layout/Flex";
import { Text } from "ui/src/components/text/Text";
import { assert } from "utilities/src/errors";
import { isMobileApp } from "utilities/src/platform";
const TOGGLE_PADDING = 4;
const OptionsSelector = styled(Tabs.List, {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderColor: "$surface3",
  outlineWidth: 0,
  borderWidth: "$spacing1",
  gap: "$gap8",
  overflow: "hidden",
  p: TOGGLE_PADDING,
  variants: {
    fullWidth: {
      true: {
        width: "100%"
      }
    },
    outlined: {
      true: {
        borderColor: "$surface3",
        borderWidth: "$spacing1"
      },
      false: {
        borderWidth: 0
      }
    },
    size: {
      small: {
        height: 30,
        gap: "$spacing6",
        borderRadius: "$rounded16"
      },
      default: {
        height: 34,
        gap: "$gap8",
        borderRadius: "$rounded20"
      },
      large: {
        height: 42,
        gap: "$gap12",
        borderRadius: "$rounded24"
      }
    }
  }
});
const TabsRovingIndicator = styled(Flex, {
  animation: "fast",
  backgroundColor: "$surface3",
  borderRadius: "$roundedFull",
  position: "absolute",
  cursor: "pointer",
  zIndex: "$mask",
  enterStyle: {
    opacity: 0
  },
  exitStyle: {
    opacity: 0
  },
  hoverStyle: {
    backgroundColor: "$surface3Hovered"
  },
  variants: {
    disabled: {
      true: {
        backgroundColor: "$surface2"
      },
      false: {
        backgroundColor: "$surface3"
      }
    }
  }
});
const OptionButton = styled(Tabs.Tab, {
  unstyled: true,
  role: "button",
  tabIndex: 0,
  disableActiveTheme: true,
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "transparent",
  borderRadius: "$roundedFull",
  cursor: "pointer",
  borderColor: "transparent",
  outlineColor: "transparent",
  px: "$spacing8",
  variants: {
    fullWidth: {
      true: {
        flex: 1
      }
    },
    size: {
      small: {
        height: "$spacing20",
        py: "$spacing2",
        px: "$padding6"
      },
      default: {
        height: "$spacing24",
        py: "$spacing2",
        px: "$padding8"
      },
      large: {
        height: "$spacing32",
        py: "$padding8",
        px: "$padding12"
      }
    },
    disabled: {
      true: {
        cursor: "unset"
      },
      false: {
        cursor: "pointer"
      }
    }
  }
});
export function SegmentedControl({
  options,
  selectedOption,
  onSelectOption,
  size = "default",
  disabled,
  fullWidth,
  outlined = true,
  gap
}) {
  assert(options.length >= 2 && options.length <= 6, "Segmented control must have between 2 and 6 options, inclusive.");
  const [tabState, setTabState] = useState({
    activeAt: null
  });
  const [hoveredIndex, setHoveredIndex] = useState();
  const setActiveIndicator = (activeAt2) => setTabState({ ...tabState, activeAt: activeAt2 });
  const { activeAt } = tabState;
  const handleOnInteraction = (type, layout) => {
    if (type === "select") {
      setActiveIndicator(layout);
    }
  };
  const activeIndicatorXAdjustment = isMobileApp ? 2.5 : 0;
  const activeIndicatorYAdjustment = isMobileApp ? -1.5 : 0;
  return <Tabs
    unstyled
    activationMode="manual"
    orientation="horizontal"
    value={selectedOption}
    onValueChange={(option) => {
      onSelectOption(option);
    }}
  ><OptionsSelector
    disablePassBorderRadius
    unstyled
    backgroundColor="transparent"
    fullWidth={fullWidth}
    outlined={outlined}
    loop={false}
    size={size}
    gap={gap}
  >{options.map((option, index) => {
    const { value, display, wrapper } = option;
    const optionButton = <OptionButton
      key={value}
      active={selectedOption === value}
      disabled={disabled}
      fullWidth={fullWidth}
      size={size}
      value={value}
      onInteraction={handleOnInteraction}
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(void 0)}
      onPress={() => {
        onSelectOption(value);
      }}
    >{display ?? <Text
      color={getOptionTextColor(selectedOption === value, hoveredIndex === index, disabled)}
      userSelect="none"
      variant={size === "large" ? "buttonLabel3" : "buttonLabel4"}
    >{value}</Text>}</OptionButton>;
    if (wrapper) {
      return cloneElement(wrapper, {
        children: optionButton
      });
    }
    return optionButton;
  })}<AnimatePresence>{activeAt && <TabsRovingIndicator
    height={activeAt.height}
    width={activeAt.width}
    x={activeAt.x - TOGGLE_PADDING + activeIndicatorXAdjustment}
    y={activeAt.y - TOGGLE_PADDING + activeIndicatorYAdjustment - (!outlined ? 1 : 0)}
  />}</AnimatePresence></OptionsSelector></Tabs>;
}
function getOptionTextColor(active, hovered, disabled = false) {
  if (disabled) {
    return active ? "$neutral2" : "$neutral3";
  }
  if (active || hovered) {
    return "$neutral1";
  }
  return "$neutral2";
}
