"use strict";
import { InterfaceEventName } from "@uniswap/analytics-events";
import {
  ARGENT_WALLET_DETECTOR_ADDRESS,
  CHAIN_TO_ADDRESSES_MAP,
  MULTICALL_ADDRESSES,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  V2_ROUTER_ADDRESSES,
  V3_MIGRATOR_ADDRESSES
} from "@uniswap/sdk-core";
import IUniswapV2PairJson from "@uniswap/v2-core/build/IUniswapV2Pair.json";
import IUniswapV2Router02Json from "@uniswap/v2-periphery/build/IUniswapV2Router02.json";
import NonfungiblePositionManagerJson from "@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json";
import V3MigratorJson from "@uniswap/v3-periphery/artifacts/contracts/V3Migrator.sol/V3Migrator.json";
import UniswapInterfaceMulticallJson from "@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json";
import { useAccount } from "hooks/useAccount";
import { useEthersProvider } from "hooks/useEthersProvider";
import { useEffect, useMemo } from "react";
import ARGENT_WALLET_DETECTOR_ABI from "uniswap/src/abis/argent-wallet-detector.json";
import EIP_2612 from "uniswap/src/abis/eip_2612.json";
import ERC20_ABI from "uniswap/src/abis/erc20.json";
import WETH_ABI from "uniswap/src/abis/weth.json";
import { WRAPPED_NATIVE_CURRENCY } from "uniswap/src/constants/tokens";
import { UniverseChainId } from "uniswap/src/features/chains/types";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
import { getContract } from "utilities/src/contracts/getContract";
import { logger } from "utilities/src/logger/logger";
const { abi: IUniswapV2PairABI } = IUniswapV2PairJson;
const { abi: IUniswapV2Router02ABI } = IUniswapV2Router02Json;
const { abi: MulticallABI } = UniswapInterfaceMulticallJson;
const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson;
const { abi: V2MigratorABI } = V3MigratorJson;
export function useContract(address, ABI, withSignerIfPossible = true, chainId) {
  const account = useAccount();
  const provider = useEthersProvider({ chainId: chainId ?? account.chainId });
  return useMemo(() => {
    if (!address || !ABI || !provider) {
      return null;
    }
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account.address ? account.address : void 0);
    } catch (error) {
      const wrappedError = new Error("failed to get contract", { cause: error });
      logger.warn("useContract", "useContract", wrappedError.message, {
        error: wrappedError,
        contractAddress: address,
        accountAddress: account.address
      });
      return null;
    }
  }, [address, ABI, provider, withSignerIfPossible, account.address]);
}
export function useV2MigratorContract() {
  const account = useAccount();
  return useContract(
    account.chainId ? V3_MIGRATOR_ADDRESSES[account.chainId] : void 0,
    V2MigratorABI,
    true
  );
}
export function useTokenContract(tokenAddress, withSignerIfPossible, chainId) {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible, chainId);
}
export function useWETHContract(withSignerIfPossible, chainId) {
  return useContract(
    chainId ? WRAPPED_NATIVE_CURRENCY[chainId]?.address : void 0,
    WETH_ABI,
    withSignerIfPossible,
    chainId
  );
}
export function useArgentWalletDetectorContract() {
  const account = useAccount();
  return useContract(
    account.chainId ? ARGENT_WALLET_DETECTOR_ADDRESS[account.chainId] : void 0,
    ARGENT_WALLET_DETECTOR_ABI,
    false
  );
}
export function useEIP2612Contract(tokenAddress) {
  return useContract(tokenAddress, EIP_2612, false);
}
export function usePairContract(pairAddress, withSignerIfPossible) {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible);
}
export function useV2RouterContract() {
  const { chainId } = useAccount();
  return useContract(chainId ? V2_ROUTER_ADDRESSES[chainId] : void 0, IUniswapV2Router02ABI, true);
}
export function useInterfaceMulticall(chainId) {
  const account = useAccount();
  const chain = chainId ?? account.chainId;
  return useContract(
    chain ? MULTICALL_ADDRESSES[chain] : void 0,
    MulticallABI,
    false,
    chain
  );
}
export function useV3NFTPositionManagerContract(withSignerIfPossible, chainId) {
  const account = useAccount();
  const chainIdToUse = chainId ?? account.chainId;
  const contract = useContract(
    chainIdToUse ? NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainIdToUse] : void 0,
    NFTPositionManagerABI,
    withSignerIfPossible,
    chainIdToUse
  );
  useEffect(() => {
    if (contract && account.isConnected) {
      sendAnalyticsEvent(InterfaceEventName.WALLET_PROVIDER_USED, {
        source: "useV3NFTPositionManagerContract",
        contract: {
          name: "V3NonfungiblePositionManager",
          address: contract.address,
          withSignerIfPossible,
          chainId: chainIdToUse
        }
      });
    }
  }, [account.isConnected, chainIdToUse, contract, withSignerIfPossible]);
  return contract;
}
export function useV4NFTPositionManagerContract(withSignerIfPossible, chainId) {
  const account = useAccount();
  const chainIdToUse = chainId ?? account.chainId;
  const contract = useContract(
    // monad testnet does not have v4 support
    chainIdToUse && chainIdToUse !== UniverseChainId.MonadTestnet ? CHAIN_TO_ADDRESSES_MAP[chainIdToUse].v4PositionManagerAddress : void 0,
    NFTPositionManagerABI,
    withSignerIfPossible,
    chainIdToUse
  );
  useEffect(() => {
    if (contract && account.isConnected) {
      sendAnalyticsEvent(InterfaceEventName.WALLET_PROVIDER_USED, {
        source: "useV4NFTPositionManagerContract",
        contract: {
          name: "V4NonfungiblePositionManager",
          address: contract.address,
          withSignerIfPossible,
          chainId: chainIdToUse
        }
      });
    }
  }, [account.isConnected, chainIdToUse, contract, withSignerIfPossible]);
  return contract;
}
