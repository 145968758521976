"use strict";
import Popover from "components/Popover";
import styled from "lib/styled-components";
import { transparentize } from "polished";
import { memo, useCallback, useEffect, useState } from "react";
import noop from "utilities/src/react/noop";
export var TooltipSize = /* @__PURE__ */ ((TooltipSize2) => {
  TooltipSize2["ExtraSmall"] = "200px";
  TooltipSize2["Small"] = "256px";
  TooltipSize2["Large"] = "400px";
  TooltipSize2["Max"] = "max-content";
  return TooltipSize2;
})(TooltipSize || {});
const getPaddingForSize = (size) => {
  switch (size) {
    case "200px" /* ExtraSmall */:
    case "max-content" /* Max */:
      return "8px";
    case "256px" /* Small */:
      return "12px";
    case "400px" /* Large */:
      return "16px 20px";
  }
};
const TooltipContainer = styled.div`
  max-width: ${({ size }) => size};
  width: ${({ size }) => size === "max-content" /* Max */ ? "auto" : `calc(100vw - 16px)`};
  cursor: default;
  padding: ${({ size }) => getPaddingForSize(size)};
  pointer-events: auto;

  color: ${({ theme }) => theme.neutral1};
  font-weight: 485;
  font-size: 12px;
  line-height: 16px;
  word-break: break-word;

  background: ${({ theme }) => theme.surface1};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.surface3};
  box-shadow: 0 4px 8px 0 ${({ theme }) => transparentize(0.9, theme.shadow1)};
`;
export const MouseoverTooltip = memo(function MouseoverTooltip2(props) {
  const { text, disabled, children, onOpen, forceShow, timeout, ...rest } = props;
  const [show, setShow] = useState(false);
  const open = () => {
    setShow(true);
    onOpen?.();
  };
  const close = () => setShow(false);
  useEffect(() => {
    if (show && timeout) {
      const tooltipTimer = setTimeout(() => {
        setShow(false);
      }, timeout);
      return () => {
        clearTimeout(tooltipTimer);
      };
    }
    return void 0;
  }, [timeout, show]);
  return <Popover
    content={text && <TooltipContainer
      size={props.size ?? "256px" /* Small */}
      onMouseEnter={disabled ? noop : open}
      onMouseLeave={disabled ? noop : close}
    >{text}</TooltipContainer>}
    show={forceShow || show}
    {...rest}
  ><div onMouseEnter={disabled ? noop : open} onMouseLeave={disabled || timeout ? noop : close}>{children}</div></Popover>;
});
const CursorFollowerContainer = styled.div`
  position: fixed;
  pointer-events: none;
  transform: translate(-50%, -50%);
`;
export function MouseFollowTooltip(props) {
  const [position, setPosition] = useState({
    x: void 0,
    y: void 0
  });
  const { text, disabled, children, onOpen, forceShow, ...rest } = props;
  const [show, setShow] = useState(false);
  const open = () => {
    setShow(true);
    onOpen?.();
  };
  const close = () => setShow(false);
  const handleMouseMove = useCallback((event) => {
    setPosition({ x: event.clientX, y: event.clientY });
  }, []);
  useEffect(() => {
    if (show && !disabled) {
      document.addEventListener("mousemove", handleMouseMove);
    }
    return () => document.removeEventListener("mousemove", handleMouseMove);
  }, [disabled, handleMouseMove, show]);
  return <div><CursorFollowerContainer
    style={{
      left: position.x ? `${position.x}px` : void 0,
      top: position.y ? `${position.y + 16}px` : void 0
    }}
  ><MouseoverTooltip {...rest} text={disabled ? null : text} forceShow={forceShow} /></CursorFollowerContainer><div onMouseEnter={disabled ? noop : open} onMouseLeave={disabled ? noop : close}>{children}</div></div>;
}
