"use strict";
import { InterfaceEventName } from "@uniswap/analytics-events";
import { useAccount } from "hooks/useAccount";
import { useWETHContract } from "hooks/useContract";
import useNativeCurrency from "lib/hooks/useNativeCurrency";
import { formatToDecimal, getTokenAddress } from "lib/utils/analytics";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { useMemo, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { useCurrencyBalance } from "state/connection/hooks";
import { useMultichainContext } from "state/multichain/useMultichainContext";
import { useTransactionAdder } from "state/transactions/hooks";
import { TransactionType } from "state/transactions/types";
import { trace } from "tracing/trace";
import { WRAPPED_NATIVE_CURRENCY } from "uniswap/src/constants/tokens";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
import { WrapType } from "uniswap/src/features/transactions/types/wrap";
import { logger } from "utilities/src/logger/logger";
const NOT_APPLICABLE = { wrapType: WrapType.NotApplicable };
var WrapInputError = /* @__PURE__ */ ((WrapInputError2) => {
  WrapInputError2[WrapInputError2["NO_ERROR"] = 0] = "NO_ERROR";
  WrapInputError2[WrapInputError2["ENTER_NATIVE_AMOUNT"] = 1] = "ENTER_NATIVE_AMOUNT";
  WrapInputError2[WrapInputError2["ENTER_WRAPPED_AMOUNT"] = 2] = "ENTER_WRAPPED_AMOUNT";
  WrapInputError2[WrapInputError2["INSUFFICIENT_NATIVE_BALANCE"] = 3] = "INSUFFICIENT_NATIVE_BALANCE";
  WrapInputError2[WrapInputError2["INSUFFICIENT_WRAPPED_BALANCE"] = 4] = "INSUFFICIENT_WRAPPED_BALANCE";
  return WrapInputError2;
})(WrapInputError || {});
export function WrapErrorText({ wrapInputError }) {
  const { chainId } = useMultichainContext();
  const native = useNativeCurrency(chainId);
  const wrapped = native?.wrapped;
  switch (wrapInputError) {
    case 0 /* NO_ERROR */:
      return null;
    case 1 /* ENTER_NATIVE_AMOUNT */:
      return <Trans i18nKey="swap.enterAmount" values={{ sym: native?.symbol }} />;
    case 2 /* ENTER_WRAPPED_AMOUNT */:
      return <Trans i18nKey="swap.enterAmount" values={{ sym: wrapped?.symbol }} />;
    case 3 /* INSUFFICIENT_NATIVE_BALANCE */:
      return <Trans i18nKey="common.insufficientTokenBalance.error" values={{ tokenSymbol: native?.symbol }} />;
    case 4 /* INSUFFICIENT_WRAPPED_BALANCE */:
      return <Trans i18nKey="common.insufficientTokenBalance.error" values={{ tokenSymbol: wrapped?.symbol }} />;
  }
}
export default function useWrapCallback(inputCurrency, outputCurrency, typedValue) {
  const account = useAccount();
  const { chainId } = useMultichainContext();
  const wethContract = useWETHContract(true, chainId);
  const wethContractRef = useRef(wethContract);
  wethContractRef.current = wethContract;
  const balance = useCurrencyBalance(account.address, inputCurrency ?? void 0);
  const inputAmount = useMemo(
    () => tryParseCurrencyAmount(typedValue, inputCurrency ?? void 0),
    [inputCurrency, typedValue]
  );
  const addTransaction = useTransactionAdder();
  const [error, setError] = useState();
  if (error) {
    throw error;
  }
  return useMemo(() => {
    if (!wethContractRef.current || !chainId || !inputCurrency || !outputCurrency) {
      return NOT_APPLICABLE;
    }
    const weth = WRAPPED_NATIVE_CURRENCY[chainId];
    if (!weth) {
      return NOT_APPLICABLE;
    }
    const hasInputAmount = Boolean(inputAmount?.greaterThan("0"));
    const sufficientBalance = inputAmount && balance && !balance.lessThan(inputAmount);
    const eventProperties = {
      token_in_address: getTokenAddress(inputCurrency),
      token_out_address: getTokenAddress(outputCurrency),
      token_in_symbol: inputCurrency.symbol,
      token_out_symbol: outputCurrency.symbol,
      chain_id: inputCurrency.chainId,
      amount: inputAmount ? formatToDecimal(inputAmount, inputAmount?.currency.decimals) : void 0
    };
    if (inputCurrency.isNative && weth.equals(outputCurrency)) {
      return {
        wrapType: WrapType.Wrap,
        execute: sufficientBalance && inputAmount ? () => trace({ name: "Wrap", op: "swap.wrap" }, async (trace2) => {
          const wethContract2 = wethContractRef.current;
          if (!wethContract2) {
            throw new Error("wethContract is null");
          }
          const network = await wethContract2.provider.getNetwork();
          if (network.chainId !== chainId || wethContract2.address !== WRAPPED_NATIVE_CURRENCY[network.chainId]?.address) {
            sendAnalyticsEvent(InterfaceEventName.WRAP_TOKEN_TXN_INVALIDATED, {
              ...eventProperties,
              contract_address: wethContract2.address,
              contract_chain_id: network.chainId,
              type: WrapType.Wrap
            });
            const error2 = new Error(`Invalid WETH contract
Please file a bug detailing how this happened - https://github.com/Uniswap/interface/issues/new?labels=bug&template=bug-report.md&title=Invalid%20WETH%20contract`);
            setError(error2);
            trace2.setError(error2, "out_of_range");
            throw error2;
          }
          const txReceipt = await trace2.child(
            { name: "Deposit", op: "wallet.send_transaction" },
            () => wethContract2.deposit({ value: `0x${inputAmount.quotient.toString(16)}` })
          );
          addTransaction(txReceipt, {
            type: TransactionType.WRAP,
            unwrapped: false,
            currencyAmountRaw: inputAmount?.quotient.toString(),
            chainId
          });
          sendAnalyticsEvent(InterfaceEventName.WRAP_TOKEN_TXN_SUBMITTED, {
            ...eventProperties,
            type: WrapType.Wrap
          });
          return txReceipt.hash;
        }) : void 0,
        inputError: sufficientBalance ? void 0 : hasInputAmount ? 3 /* INSUFFICIENT_NATIVE_BALANCE */ : 1 /* ENTER_NATIVE_AMOUNT */
      };
    } else if (weth.equals(inputCurrency) && outputCurrency.isNative) {
      return {
        wrapType: WrapType.Unwrap,
        execute: sufficientBalance && inputAmount ? () => trace({ name: "Wrap", op: "swap.wrap" }, async (trace2) => {
          try {
            const wethContract2 = wethContractRef.current;
            if (!wethContract2) {
              throw new Error("wethContract is null");
            }
            const txReceipt = await trace2.child(
              { name: "Withdraw", op: "wallet.send_transaction" },
              () => wethContract2.withdraw(`0x${inputAmount.quotient.toString(16)}`)
            );
            addTransaction(txReceipt, {
              type: TransactionType.WRAP,
              unwrapped: true,
              currencyAmountRaw: inputAmount?.quotient.toString(),
              chainId
            });
            sendAnalyticsEvent(InterfaceEventName.WRAP_TOKEN_TXN_SUBMITTED, {
              ...eventProperties,
              type: WrapType.Unwrap
            });
            return txReceipt.hash;
          } catch (error2) {
            logger.warn("useWrapCallback", "useWrapCallback", "Failed to wrap", error2);
            throw error2;
          }
        }) : void 0,
        inputError: sufficientBalance ? void 0 : hasInputAmount ? 4 /* INSUFFICIENT_WRAPPED_BALANCE */ : 2 /* ENTER_WRAPPED_AMOUNT */
      };
    } else {
      return NOT_APPLICABLE;
    }
  }, [chainId, inputCurrency, outputCurrency, inputAmount, balance, addTransaction]);
}
