"use strict";
import { Percent } from "@uniswap/sdk-core";
import Expand from "components/Expand";
import QuestionHelper from "components/QuestionHelper";
import { Input, InputContainer } from "components/Settings/Input";
import Row, { RowBetween } from "components/deprecated/Row";
import styled from "lib/styled-components";
import { useState } from "react";
import { Trans } from "react-i18next";
import { useUserSlippageTolerance } from "state/user/hooks";
import { SlippageTolerance } from "state/user/types";
import { CautionTriangle, ThemedText } from "theme/components";
import { Text } from "ui/src";
import { useFormatter } from "utils/formatNumbers";
var SlippageError = /* @__PURE__ */ ((SlippageError2) => {
  SlippageError2["InvalidInput"] = "InvalidInput";
  return SlippageError2;
})(SlippageError || {});
const Option = styled(Row)`
  width: auto;
  cursor: pointer;
  padding: 6px 12px;
  text-align: center;
  gap: 4px;
  border-radius: 12px;
  background: ${({ isActive, theme }) => isActive ? theme.surface3 : "transparent"};
  pointer-events: ${({ isActive }) => isActive && "none"};
`;
const Switch = styled(Row)`
  width: auto;
  padding: 4px;
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px;
`;
const NUMBER_WITH_MAX_TWO_DECIMAL_PLACES = /^(?:\d*\.\d{0,2}|\d+)$/;
const MINIMUM_RECOMMENDED_SLIPPAGE = new Percent(5, 1e4);
const MAXIMUM_RECOMMENDED_SLIPPAGE = new Percent(1, 100);
function useFormatPercentInput() {
  const { formatPercent } = useFormatter();
  return (slippage) => formatPercent(slippage).slice(0, -1);
}
export default function MaxSlippageSettings({ autoSlippage }) {
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance();
  const { formatPercent } = useFormatter();
  const formatPercentInput = useFormatPercentInput();
  const defaultSlippageInputValue = userSlippageTolerance !== SlippageTolerance.Auto && !userSlippageTolerance.equalTo(autoSlippage) ? formatPercentInput(userSlippageTolerance) : "";
  const [slippageInput, setSlippageInput] = useState(defaultSlippageInputValue);
  const [slippageError, setSlippageError] = useState(false);
  const [isOpen, setIsOpen] = useState(defaultSlippageInputValue.length > 0);
  const parseSlippageInput = (value) => {
    if (value.length > 0 && !NUMBER_WITH_MAX_TWO_DECIMAL_PLACES.test(value)) {
      return;
    }
    setSlippageInput(value);
    setSlippageError(false);
    if (value.length === 0) {
      setUserSlippageTolerance(SlippageTolerance.Auto);
      return;
    }
    if (value === ".") {
      return;
    }
    try {
      const parsed = Math.floor(Number.parseFloat(value) * 100);
      if (parsed > 5e3) {
        setUserSlippageTolerance(new Percent(5e3, 1e4));
        setSlippageError("InvalidInput" /* InvalidInput */);
      } else {
        setUserSlippageTolerance(new Percent(parsed, 1e4));
      }
    } catch (e) {
      setSlippageError("InvalidInput" /* InvalidInput */);
    }
  };
  const tooLow = userSlippageTolerance !== SlippageTolerance.Auto && userSlippageTolerance.lessThan(MINIMUM_RECOMMENDED_SLIPPAGE);
  const tooHigh = userSlippageTolerance !== SlippageTolerance.Auto && userSlippageTolerance.greaterThan(MAXIMUM_RECOMMENDED_SLIPPAGE);
  return <Expand
    testId="max-slippage-settings"
    padding="6px 0px"
    isOpen={isOpen}
    onToggle={() => setIsOpen(!isOpen)}
    header={<Row width="auto"><ThemedText.BodyPrimary><Trans i18nKey="settings.maxSlippage" /></ThemedText.BodyPrimary><QuestionHelper text={<Trans i18nKey="swap.settings.transactionRevertPrice" />} /></Row>}
    button={<ThemedText.BodyPrimary>{userSlippageTolerance === SlippageTolerance.Auto ? <Trans i18nKey="common.automatic" /> : formatPercent(userSlippageTolerance)}</ThemedText.BodyPrimary>}
  ><RowBetween gap="md"><Switch><Option
    onClick={() => {
      setSlippageInput("");
      setUserSlippageTolerance(SlippageTolerance.Auto);
    }}
    isActive={userSlippageTolerance === SlippageTolerance.Auto}
  ><ThemedText.BodyPrimary><Trans i18nKey="common.automatic" /></ThemedText.BodyPrimary></Option><Option
    onClick={() => {
      setUserSlippageTolerance(autoSlippage);
    }}
    isActive={userSlippageTolerance !== SlippageTolerance.Auto}
  ><ThemedText.BodyPrimary><Trans i18nKey="common.custom" /></ThemedText.BodyPrimary></Option></Switch><InputContainer gap="md" error={!!slippageError}><Input
    data-testid="slippage-input"
    placeholder={formatPercentInput(autoSlippage)}
    value={slippageInput}
    onChange={(e) => parseSlippageInput(e.target.value)}
    onBlur={() => {
      setSlippageInput(defaultSlippageInputValue);
      setSlippageError(false);
    }}
  /><Text variant="body1" color={slippageError ? "$statusCritical" : "$neutral1"}>
            %
          </Text></InputContainer></RowBetween>{tooLow || tooHigh ? <RowBetween gap="md"><CautionTriangle /><ThemedText.BodySmall color="deprecated_accentWarning">{tooLow ? <Trans
    i18nKey="swap.slippageBelow.warning"
    values={{ amt: formatPercent(MINIMUM_RECOMMENDED_SLIPPAGE) }}
  /> : <Trans i18nKey="swap.frontrun.warning" />}</ThemedText.BodySmall></RowBetween> : null}</Expand>;
}
