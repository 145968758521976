import { createStyledContext } from "tamagui";
export const buttonStyledContext = createStyledContext({
  size: "medium",
  variant: "default",
  singleLine: true,
  emphasis: "primary",
  disabled: false
});
export const lineHeights = {
  xxsmall: "$micro",
  xsmall: "$small",
  small: "$small",
  medium: "$large",
  large: "$large"
};
export const lineHeightFallbacks = {
  $micro: 16,
  $small: 16,
  $medium: 20,
  $large: 24
};
